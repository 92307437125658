//Contact.jsx
import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <aside>
      <div id='contactPhoto'>
        <h1 id='Contact'>
          CONTACT
        </h1>
      </div>
      <p id='quote'>CONTACT US TO GET A QUOTE</p>
      <p id='phone'>Phone: <a href="tel:+1(412)680-7102" id='phoneLink'>+1(412)680-7102</a></p>
      <p id='email'>EMAIL: <a href="mailto:info@nooreldsolutions.com" id='MailLink'> info@nooreldsolutions.com</a></p>
      <p id='Touch'>Get in touch with us at <a href="mailto:info@nooreldsolutions.com" id='link'>info@nooreldsolutions.com</a></p>
    </aside>
  )
}


export default Contact