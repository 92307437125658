import { createSlice } from "@reduxjs/toolkit";

const SidebarSlice = createSlice({
    name : "SidebarSlice",
    initialState:{
        isVisible:true
    },
    reducers:{
        changeIsVisible(state , action){
            state.isVisible = action.payload
        }
    }
})

export default SidebarSlice.reducer
export const {changeIsVisible} = SidebarSlice.actions
 