import Sidebar from './Components/Sidebar/Sidebar';
import './App.css';
import Home from './Components/Home/Home';
import { Route, Routes } from 'react-router-dom';
import Contact from './Components/Contact/Contact';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './Components/TermsOfService/TermsOfService';
function App() {
  return (
    <div className="App">
      <Sidebar />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Contact'  element={<Contact/>} />
        <Route path="/Privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/Terms-of-service" element={<TermsOfService/>} />
        <Route path='*' element={<Home/>}/>
      </Routes>
    </div>
  );
}

export default App;
