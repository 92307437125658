// JSX FILE
import React from "react";
import "./PrivacyPolicy.css";
import { useSelector } from "react-redux";
const PrivacyPolicy = () => {
  const {isVisible} = useSelector(state=>state.SidebarSlice)
  
  return (
    <section>
      <div id="privacyPphoto">
        <h1 id="Policy"> PRIVACY POLICY </h1>
      </div>
      <div id={isVisible ?"policy-text" : "policy-text-clos"}>
        <p className="topics">Privacy Policy</p>
        <p className="text1">
          <span>NOOR ELD </span>takes your privacy very seriously. This Privacy
          Policy applies to the Service (as defined in our Terms of Service).
          Any terms used but not defined in this Privacy Policy have the
          meanings assigned to them in our Terms of Service.
        </p>
        <p className="topics">Information We Collect</p>
        <p className="text1">
          The primary reason we collect information from you is in order to
          provide you with our Service and to facilitate your use of the
          Service. We may collect the following information about you, which may
          include non-public personal information which can be used to identify
          an individual ("Personal Information").
        </p>
        <p className="topics">Account and Profile Information</p>
        <p className="text1">
          When accessing the Service, you provide us with Your Data and Personal
          Information, including but not limited to your name, mobile phone
          number, email address, mailing address and driver’s license
          information. Personal Information that we collect may also include
          documentation uploaded to your account by you or third parties to whom
          you have granted access to your account (including but not limited to
          motor vehicle records, proof(s) of insurance, and your transportation
          worker identification credential).
        </p>
        <p className="topics">Information Related to Your Use of the Service</p>
        <p className="text1">
          When accessing the Service, you provide us with Your Data and Personal
          Information, including but not limited to your name, mobile phone
          number, email address, mailing address and driver’s license
          information. Personal Information that we collect may also include
          documentation uploaded to your account by you or third parties to whom
          you have granted access to your account (including but not limited to
          motor vehicle records, proof(s) of insurance, and your transportation
          worker identification credential).
        </p>
        <p className="topics">Information Related to Your Use of the Service</p>
        <p className="text1">
          We automatically collect, record and store certain information related
          to your use of the Service such as:
        </p>
        <p className="text1">Your Data (as defined in our Terms of Service)</p>
        <p className="text1">Vehicle information</p>
        <p className="text1">
          Information related to your driving of such vehicle (including but not
          limited to driver performance data, driving time, on and off duty
          status, etc)
        </p>
        <p className="text1">
          Information you send to third parties using the Service, such as any
          messages sent to third parties via the Service, or the transmission of
          Department of Transportation inspection reports.
        </p>
        <p className="text1">
          Information about your usage of the Service including duration of use,
          screens or webpages you visit, and other information about your
          activities within the Service.
        </p>
        <p className="text1">Information Sent by Your Mobile Device.</p>
        <p className="text1">
          We collect certain information indirectly from you while you use the
          Service such as information about the device you use, hardware and
          software you use when accessing the Service, and your IP address.
        </p>
        <p className="topics">Location Information</p>
        <p className="text1">
          We may collect and store information about your location by converting
          your IP address into a geo-location or by accessing your mobile
          device’s GPS coordinates via the location services on your device.
        </p>
        <p className="topics">How We Share Information with Others</p>
        <p className="text1">To Provide the Service</p>
        <p className="text1">
          We share information with others in order to provide you with the
          Service, including
        </p>
        <p className="text1">
          Motor carrier(s) with which you have an employer, contractor or other
          business relationship.
        </p>
        <p className="text1">
          Governmental agencies such as the Department of Transportation or
          FMCSA upon their request and in order to facilitate transmission of
          messages and reports by you to these agencies.
        </p>
        <p className="text1">
          Third parties with whom we have business relationships in order to
          provide you with the Service.
        </p>
        <p className="topics">Our Service Providers</p>
        <p className="text1">
          We also share information with third parties who provide services to
          us, including various analytics services, that help us monitor the
          performance of the Service and make improvements to the Service based
          on how you and other users are interacting with and using the Service.
        </p>
        <p className="topics">Compliance with Laws</p>
        <p className="text1">
          Law enforcement, government officials or other third parties (i) in
          connection with a formal request, subpoena, court order, or similar
          legal procedure; or (ii) when we believe in good faith that disclosure
          is necessary to comply with the law, prevent physical harm or
          financial loss, report suspected illegal activity, or to investigate
          violations of our Terms of Use.
        </p>
        <p className="topics">
          Your Choices under this Privacy Policy Choice to Opt-Out
        </p>
        <p className="text1">
          We offer you choices regarding the collection, use and sharing of your
          information including Your Data and Your Personal Information and we
          will respect the choices you make. Please note that if you decide not
          to provide us with certain information, you may not be able to access
          the Service or portions thereof.
        </p>
        <p className="topics">Opt-Out & Modification</p>
        <p className="text1">
          If you want to opt-out of providing us information that we collect
          from you as described in this Privacy Policy, contact us at: 
          <a href="mailto:info@nooreldsolutions.com">
            info@nooreldsolutions.com
          </a>
          . If you would like to modify information that you have provided us
          within the Service, including Personal Information, you are able to
          modify certain information by logging into your account.
        </p>
        <p className="topics">Notices to You and Changes to this Policy</p>
        <p className="text1">
          We may occasionally send you push notifications through our Service,
          including our mobile application, to send you Service-related
          notifications or notifications of changes to this Privacy Policy or
          our Terms of Service. You may at any time opt-out from receiving these
          types of communications by turning them off at the device level
          through your settings or through the mobile application settings.
        </p>
        <p className="topics">Contact Us</p>
        <p className="text1">
          For any questions regarding this Privacy Policy, please email us at:
          <a href="mailto:info@nooreldsolutions.com">
            info@nooreldsolutions.com
          </a>
          or contact us by mail at:
        </p>
        <p className="text1">NOOR ELD SOLUTIONS LLC</p>
        <p className="text1">3324 Babcock BLVD. Pittsburgh, PA 15237 USA</p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
