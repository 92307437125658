import React, { useEffect } from 'react';
import './Sidebar.css'; 
import logo from '../pictures/NOOR ELD.png'; 
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseCircle } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsVisible } from '../../Store/slice/SidebarSlice';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { isVisible } = useSelector(state => state.SidebarSlice);
  const ChangeIsVisible = (boolean) => { dispatch(changeIsVisible(boolean)) };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        ChangeIsVisible(false);
      } else {
        ChangeIsVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]); 
  
  const toggleSidebar = () => {
    ChangeIsVisible(!isVisible);
  };

  const closeSidebar = () => {
    if (window.innerWidth > 300) {
      ChangeIsVisible(false);
    }
  };

  return (
    <div>
      <GiHamburgerMenu onClick={toggleSidebar} id='menu' />
      <div className={`sidebar ${isVisible ? 'show' : 'hide'}`}>
        <IoCloseCircle onClick={closeSidebar} id='closeBTN' />
        <img src={logo} alt="Logo" id='sidebarLogo' />
        <NavLink to='/' id='NOOR'>Noor ELD</NavLink>
        <NavLink to="/" onClick={closeSidebar}>Home</NavLink>
        <NavLink to="http://d274o5b54pb661.cloudfront.net/" id='portal'target="_blank" >ELD Login</NavLink>
        <NavLink to="/Contact" onClick={closeSidebar}>Contact</NavLink>
        <NavLink to="/Privacy-policy" onClick={closeSidebar}>Privacy Policy</NavLink>
        <NavLink to="/Terms-of-service" onClick={closeSidebar}>Terms of Service</NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
