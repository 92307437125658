import React from 'react'
import "./Home.css"
import logo from '../pictures/NOOR ELD.png'
import { NavLink } from 'react-router-dom'

const Home = () => {
    return (
        <div>
            <header>
                <div id='GenerealPhoto' >
                    <h1 id='top'>NOOR ELD</h1>
                </div>
                <div id='texts'>
                    <h2 id='greeting'>WELCOME</h2>
                    <img src={logo} alt="" id='homeLogo' />
                </div>
                <div id='services'>
                    <h3 id='demonstration'>OUR SERVICES:</h3>
                    <ul typeof='square' id='list'>
                        <li>Edit/Make notes to eLogBook due to ELD device failure to operate properly
                        </li>
                        <li>Our support helps companies and drivers to not get violations using ELDs systems.
                        </li>
                        <li>Our support team assists drivers 24/7 with getting malfunction letters from ELD providers and notifies <br /> management of the company.</li>
                        <li>We provide 24/7 driver and company owner support with potential HOS violations</li>
                    </ul>
                </div>
                <div id='btns'>
                    <button className='BtnLinks'>
                        <NavLink to="/Contact" id='go-to-contact' target="_blank" >LEARN MORE</NavLink>
                    </button>
                    <button className='BtnLinks'>
                        <NavLink to="http://d274o5b54pb661.cloudfront.net/" id='go-to-portal'target="_blank" >ELD Login</NavLink>
                    </button>
                    <div id="footer">
                        <p id='Email'>contact us at: <a href="mailto:info@nooreldsolutions.com" id='footermail'>info@nooreldsolutions.com</a>
                        </p>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Home
